import React from "react";
import { default as Link } from "../components/link";

import Layout from "../components/layouts/default";
import SEO from "../components/global/seo";
import { default as Header } from "../components/header";
import Section from "../components/section";
import Column from "../components/column";

export default () => (
    <Layout className="wrapper--dark">
        <SEO
            title="Datenschutz"
            description="Der Schutz und die Vertraulichkeit personenbezogener Daten ist für uns von besonderer Bedeutung. Hier erfahren Sie mehr."
        />
        <article>
            <Header title="Datenschutz" classes={["section dark"]}>
                <p>
                    Für uns, eHealth Experts GmbH, ist der Schutz und die
                    Vertraulichkeit personenbezogener Daten von besonderer
                    Bedeutung. Wir erheben und verwenden personenbezogene Daten
                    ausschließlich im Rahmen der gesetzlichen Bestimmungen, also
                    des geltenden deutschen und europäischen Datenschutzrechts.
                </p>
            </Header>
            <Section classes={["section--masked section--narrow"]}>
                <Column>
                    <p>
                        Mit dieser Datenschutzerklärung informieren wir
                        Seitenbesucher darüber, welche personenbezogenen Daten
                        wir erheben und zu welchem Zweck wir die Daten nutzen
                        werden sowie darüber, welche Wahlmöglichkeiten
                        Seitenbesucher hinsichtlich ihrer personenbezogenen
                        Daten haben. Wir haben organisatorische, vertragliche
                        und technische Sicherheitsmaßnahmen getroffen, um sicher
                        zu stellen, dass die Vorschriften der Datenschutzgesetze
                        eingehalten werden und zufällige oder vorsätzliche
                        Manipulationen, Verlust, Zerstörung oder der Zugriff
                        unberechtigter Personen verhindert werden. Gleichwohl
                        ist eine 100%-ige Sicherheit hinsichtlich der
                        Datenübertragung im Internet nicht möglich.
                    </p>

                    <h2 class="h3">
                        Verantwortliche Stelle im Sinne des Datenschutzes
                    </h2>
                    <p>
                        <strong>eHealth Experts GmbH</strong>
                        <br />
                        Emil-Figge-Straße 85
                        <br />
                        44227 Dortmund
                    </p>

                    <p>
                        <abbr title="E-Mail">e_</abbr> info (at)
                        ehealthexperts.de
                        <br />
                        <abbr title="Telefon">t_</abbr> +49 231 97 61 95 01
                        <br />
                        <abbr title="Fax">f_</abbr> +49 231 97 61 95 00
                    </p>

                    <h3 class="h4">Kontaktdaten der Datenschutzbeauftragten</h3>
                    <h4 class="h5">Per E-Mail</h4>
                    <p>
                        datenschutz (at) ehealthexperts.de
                        <br />
                        <Link external={true} to="/ehex.asc">
                            PGP-Key
                        </Link>
                        <br />
                        Fingerprint: 47E1AF7FC5EEE92ABDE8EC021D07CFAF3FA9D554
                    </p>

                    <h4 class="h5">Per Post</h4>
                    <p>
                        eHealth Experts GmbH
                        <br />
                        Datenschutzbeauftragte
                        <br />
                        Albrechtstraße 11
                        <br />
                        10117 Berlin
                    </p>

                    <h2 class="h3">SSL- bzw. TLS-Verschlüsselung</h2>
                    <p>
                        Diese Seite nutzt aus Sicherheitsgründen und zum Schutz
                        der Übertragung vertraulicher Inhalte, wie zum Beispiel
                        Bestellungen oder Anfragen, die Sie an uns senden, eine
                        SSL- bzw. TLS-Verschlüsselung. Eine verschlüsselte
                        Verbindung erkennen Sie daran, dass die Adresszeile des
                        Browsers von “http://” auf “https://” wechselt und an
                        dem Schloss-Symbol in Ihrer Browserzeile. Wenn die SSL-
                        bzw. TLS-Verschlüsselung aktiviert ist, können die
                        Daten, die Sie an uns übermitteln, nicht von Dritten
                        mitgelesen werden.
                    </p>

                    <h2 class="h3">Zugriffsdaten</h2>
                    <p>
                        Wir erheben Daten über jeden Zugriff auf das
                        Onlineangebot (sogenannte Serverlogfiles). Zu den
                        Zugriffsdaten gehören
                    </p>

                    <ul>
                        <li>Name der abgerufenen Webseite,</li>
                        <li>Datei,</li>
                        <li>Datum und Uhrzeit des Abrufs,</li>
                        <li>übertragene Datenmenge,</li>
                        <li>Meldung über erfolgreichen Abruf,</li>
                        <li>Browsertyp nebst Version,</li>
                        <li>das Betriebssystem des Seitenbesuchers,</li>
                        <li>Referrer-URL (die zuvor besuchte Seite) und</li>
                        <li>der anfragende Provider.</li>
                    </ul>

                    <p>
                        Die Protokolldaten werden zum Zweck des Betriebs, der
                        Sicherheit und der Optimierung des Onlineangebotes
                        verwendet. Grundlage für die Datenverarbeitung ist unser
                        berechtigtes Interesse an einer sicheren Bereitstellung
                        und Betrieb der Webseite nach Art. 6 Abs. 1 Buchstabe f
                        DSGVO.
                    </p>

                    <p>
                        Logfiles, die die IP-Adressen enthalten, speichern wir
                        für die Dauer von 7 Tagen. Wenn jedoch ein konkreter
                        Verdacht einer Straftat im Zusammenhang mit der Nutzung
                        des Internetauftritts besteht, werden die Logfiles bis
                        zur endgültigen Klärung des jeweiligen Vorfalls von der
                        Löschung ausgenommen.
                    </p>

                    <h2 class="h3">Kontakt/Anfragen</h2>
                    <p>
                        Soweit Sie uns im Rahmen von Anfragen personenbezogene
                        Daten zur Verfügung gestellt haben, verwenden wir diese
                        nur zur Beantwortung Ihrer Anfrage. Im Falle allgemeiner
                        Anfragen werden die Daten nach Beantwortung Ihrer
                        Anfrage gelöscht, es sei denn, dass für weitere
                        Rückfragen eine Speicherung notwendig ist.
                        Rechtsgrundlage dafür ist Artikel 6 Abs. 1 Buchstabe f
                        DSGVO, um unserem Interesse nachzukommen sie bestmöglich
                        über unser Produkt zu informieren. Die Löschung erfolgt,
                        wenn ersichtlich ist, dass mit keinen weiteren
                        Rückfragen mehr zu rechnen ist.
                    </p>

                    <p>
                        Wir weisen darauf hin, dass die Vertraulichkeit von
                        E-Mails über die Mailadresse info (at) ehealthexperts.de
                        grundsätzlich nicht gewährleistet werden kann. Für
                        vertrauliche Informationen empfehlen wir den Postweg
                        oder eine telefonische Kontaktaufnahme.
                    </p>

                    <h2 class="h3">Ihre Rechte</h2>

                    <h3 class="h4">Einwilligung und Widerrufsvorbehalt</h3>
                    <p>
                        Soweit wir Daten für einen Zweck nutzen, der nach den
                        gesetzlichen Bestimmungen die Einwilligung des
                        Seitenbesuchers erfordert, werden wir diesen stets um
                        sein ausdrückliches Einverständnis bitten und seine
                        Einwilligung protokollieren. Der Seitenbesucher kann
                        sein einmal gegebenes Einverständnis jederzeit
                        widerrufen.
                    </p>

                    <h3 class="h4">
                        Auskunft, Datenberichtigung und -Löschung
                    </h3>
                    <p>
                        Soweit wir personenbezogene Daten nicht mehr benötigen
                        oder aufgrund von gesetzlichen Pflichten aufbewahren
                        müssen, werden diese gelöscht. Der Seitenbesucher hat
                        jederzeit das Recht, kostenlos Auskunft über die
                        Herkunft seiner personenbezogenen Daten zu verlangen
                        sowie über Art und Umfang der Speicherung und Nutzung
                        dieser Daten. Der Seitenbesucher kann die Berichtigung
                        und – falls die Speicherung unzulässig oder nicht mehr
                        erforderlich ist – Löschung seiner Daten verlangen.
                    </p>

                    <h3 class="h4">
                        Beschwerderecht bei der zuständigen Aufsichtsbehörde
                    </h3>
                    <p>
                        Im Falle datenschutzrechtlicher Verstöße steht Ihnen
                        nach Art. 77 Abs. 1 DSGVO ein Beschwerderecht bei einer
                        Datenschutz-Aufsichtsbehörde zu.
                    </p>

                    <h3 class="h4">Einwilligung und Widerrufsvorbehalt</h3>
                    <p>
                        Zusätzlich steht Ihnen das Widerspruchsrecht gegen die
                        Verarbeitung zu.
                    </p>
                </Column>
            </Section>
        </article>
    </Layout>
);
